// trigger when page is ready
$(document).ready(function (){

  // jQuery Valdate + Bootstrap Tooltip
  $(".consultation-form").validate({
    rules: {
      contact_email: { 
        required: true,
        email: true,   
      },
      contact_name: { required: true },
      contact_phone: { required: true }
    },
    messages: {
      contact_email: {
        required: "We need an email address to contact you.",
        email:"This email doesn’t look quite right."
      },
      contact_name: "How should we address you?",
      contact_phone: "What is the best way to call you?",
      contact_locations: "Please select at least one item",
    },
    tooltip_options: {
      contact_email: { placement: 'top' },
      contact_name: { placement: 'top' }
    },
    // submitHandler: function(form) {
    //   $(".form-errors").html('<div class="alert alert-success">No errors.  Like a boss.</div>');
    // },
    // invalidHandler: function(form, validator) {
    //   $(".form-errors").html('<div class="alert alert-danger">There be ' + validator.numberOfInvalids() + ' error' + (validator.numberOfInvalids() > 1 ? 's' : '') + ' here.  OH NOES!!!!!</div>');
    // }
  });

  // jQuery Valdate + Bootstrap Tooltip
  $("#contact-main").validate({
    rules: {
      contact_email: { 
        required: true,
        email: true,   
      },
      contact_name: { required: true }
    },
    messages: {
      contact_email: {
        required: "We need an email address to contact you.",
        email:"This email doesn’t look quite right."
      },
      contact_name: "How should we address you?",
      contact_phone: "What is the best way to call you?",
    },
    tooltip_options: {
      contact_email: { placement: 'top' },
      contact_name: { placement: 'top' }
    },
    // submitHandler: function(form) {
    //   $(".form-errors").html('<div class="alert alert-success">No errors.  Like a boss.</div>');
    // },
    // invalidHandler: function(form, validator) {
    //   $(".form-errors").html('<div class="alert alert-danger">There be ' + validator.numberOfInvalids() + ' error' + (validator.numberOfInvalids() > 1 ? 's' : '') + ' here.  OH NOES!!!!!</div>');
    // }
  });

  // jQuery Valdate + Bootstrap Tooltip
  $("#contact-overlay").validate({
    rules: {
      contact_email: { 
        required: true,
        email: true,   
      },
      contact_name: { required: true }
    },
    messages: {
      contact_email: {
        required: "We need an email address to contact you.",
        email:"This email doesn’t look quite right."
      },
      contact_name: "How should we address you?",
      contact_phone: "What is the best way to call you?",
    },
    tooltip_options: {
      contact_email: { placement: 'top' },
      contact_name: { placement: 'top' }
    },
    // submitHandler: function(form) {
    //   $(".form-errors").html('<div class="alert alert-success">No errors.  Like a boss.</div>');
    // },
    // invalidHandler: function(form, validator) {
    //   $(".form-errors").html('<div class="alert alert-danger">There be ' + validator.numberOfInvalids() + ' error' + (validator.numberOfInvalids() > 1 ? 's' : '') + ' here.  OH NOES!!!!!</div>');
    // }
  });

});
