// remap jQuery to $
(function($){

		var domains = ['gmail.com', 'aol.com'];
		var secondLevelDomains = ['hotmail']
		var topLevelDomains = ["com", "net", "org"];

		var superStringDistance = function(string1, string2) {
		  // a string distance algorithm of your choosing
		}

		$('contact_email').on('blur', function() {
		  $(this).mailcheck({
		    domains: domains,                       // optional
		    secondLevelDomains: secondLevelDomains, // optional
		    topLevelDomains: topLevelDomains,       // optional
		    distanceFunction: superStringDistance,  // optional
		    suggested: function(element, suggestion) {
		      // callback code
		    },
		    empty: function(element) {
		      // callback code
		    }
		  });
		});

})(window.jQuery);

// {
//   address: 'test',          // the address; part before the @ sign
//   domain: 'gmail.com',    // the suggested domain
//   full: 'test@gmail.com'  // the full suggested email
// }