function overlaySwitch(open, overlay, close) {
  var urlPath = window.location.hash;
  var pageURL = document.location.href
  var scroll = null;
  $(open).on( 'click', function(event) {
    event.preventDefault();
    scroll = $(window).scrollTop();
    // console.log("Overlay set the scroll position to: " + scroll);
    $(overlay).fadeIn("fast");
    $('body').toggleClass('noscroll');
    window.history.pushState('forward', null, urlPath+overlay);
  });
  $(close).on( 'click', function(event) {
    event.preventDefault();
    $('body').toggleClass('noscroll');
    $(window).scrollTop(scroll);
    $(overlay).fadeToggle("fast");
    history.back();
  });
  $(window).on('popstate', function () {
      $(overlay).fadeOut('fast');
      $('body').removeClass('noscroll');
      $(window).scrollTop(scroll);
  });
  $(document).keyup(function(e) {
    e.preventDefault();
    if (e.keyCode == 27) {
      $('body').removeClass('noscroll');
      $(overlay).fadeOut('fast');
      $(window).scrollTop(scroll);
      window.history.replaceState('', 'Title', pageURL)
    }
  });
}

// For video overlay, so the video pauses and plays
function videoOverlaySwitch(open, overlay, close) {
  var urlPath = window.location.hash;
  var pageURL = document.location.href
  var videoOverlay = $(overlay + ' video');
  var scroll = null;
  $(open).on( 'click', function(event) {
    event.preventDefault();
    scroll = $(window).scrollTop();
    // console.log("Overlay set the scroll position to: " + scroll);
    $(overlay).fadeIn("fast");
    $('body').toggleClass('noscroll');
    window.history.pushState('forward', null, urlPath+overlay);
    videoOverlay.get(0).play();
  });
  $(close).on( 'click', function(event) {
    event.preventDefault();
    $('body').toggleClass('noscroll');
    $(window).scrollTop(scroll);
    $(overlay).fadeToggle("fast");
    videoOverlay.get(0).pause();
    history.back();
  });
  $(window).on('popstate', function () {
      $(overlay).fadeOut('fast');
      $('body').removeClass('noscroll');
      videoOverlay.get(0).pause();
      $(window).scrollTop(scroll);
  });
  $(document).keyup(function(e) {
    e.preventDefault();
    if (e.keyCode == 27) {
      $('body').removeClass('noscroll');
      $(overlay).fadeOut('fast');
      $(window).scrollTop(scroll);
      videoOverlay.get(0).pause();
      window.history.replaceState('', 'Title', pageURL)
    }
  });
}

function showHideOverflowBar() {
  var menuParent = $('.menu-wrap').outerHeight();
  var menuChild = $('.menu-wrap .all-pages').outerHeight();
  if ( menuParent < menuChild ){
    $('.overlay-wrap .overflow-bar').fadeIn('fast');
  } else{
    $('.overlay-wrap .overflow-bar').fadeOut('fast');
  }
}

/* trigger when page is ready */
$(document).ready(function (){

  // Blog Archives
  overlaySwitch('#openPostArchiveOverlay', '#postArchiveOverlay', '#closePostArchiveOverlay');

  // Blog Tags
  overlaySwitch('#openPostTagOverlay', '#postTagOverlay', '#closePostTagOverlay');

  // Social Accounts
  overlaySwitch('#openSocialAccountsOverlay', '#socialAccountsOverlay', '#closeSocialAccountsOverlay');

  // Locations
  overlaySwitch('#openLocations', '#locationsOverlay', '#closeLocationsOverlay');

  // Contact Form
  overlaySwitch('#openContactForm', '#contactFormOverlay', '#closeContactFormOverlay');

  // Phone
  overlaySwitch('#openPhoneOverlay', '#phoneOverlay', '#closePhoneOverlay');

  // Menu
  overlaySwitch('#openMainNav', '#mainNavOverlay', '#closeMainNavOverlay');
  
  // Video
  videoOverlaySwitch('#openHeroVideoOverlay', '#heroVideoOverlay', '#closeHeroVideoOverlay');
  
  // PPC Overlay
  overlaySwitch('#openPpcOverlay', '#ppcFormOverlay', '#closePpcFormOverlay');

  $('textarea').on('paste input', function () {
    if ($(this).outerHeight() > this.scrollHeight){
        $(this).height(1)
    }
    while ($(this).outerHeight() < this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth"))){
        $(this).height($(this).height() + 1)
    }
  });

  $('.page-group-link').on( 'click', function(overlay) {
    overlay.preventDefault();
    $(this).parent().toggleClass("expanded");
  });

  // Show/Hide "overflow-bar" for menu
  $('#openMainNav').on( 'click', function(event) {
    showHideOverflowBar();
  });
  $('.menu-wrap .all-pages .page-group-link').on( 'click', function(event) {
    showHideOverflowBar();
  });

});

$(window).resize(function(){
  showHideOverflowBar();  
});
