// Looping Video Init
document.addEventListener('DOMContentLoaded', function() {
  LoopingVideo('video.looping-video');
});

function coolFunc(longParamName) {
 //console.log("I am main.js: "+ longParamName);
}
//coolFunc('this is my message');

// remap jQuery to $
(function($){

  // change nav styles after it passes the hero header
  function navChangeOnScroll(nav, scrollTo) {
    if ($(window).scrollTop() > scrollTo) {
      $(nav).removeClass('larger');
      $(nav).addClass('smaller');
    } else {
      $(nav).removeClass('smaller');
      $(nav).addClass('larger');
    };
  }

  // This function bumps the item just before the last into the next
  // row so there isn't a hanging Chad anymore.
  function unHangChad(itemsPerRow, wrapper, item) {
    var blockWrap = $(wrapper);
    var blocks = blockWrap.children(item);
    var blockCount = blocks.length;
    var blockBeforeLast = blocks.eq(-2);
    // console.log('There are '+blockCount+' blocks.');
    if (Math.max(blockCount, itemsPerRow) % itemsPerRow == 1) {
      $('<div class="hangingchad-break"></div>').insertBefore(blockBeforeLast);
    }
  }

  $(window).load(function() {
    navChangeOnScroll('.site-navigation', 300);
  });

  /* trigger when page is ready */
  $(document).ready(function (){

    var imageSetOverlay;
    var imageSetOverlayClass = 'overlay-image-case';

    $('div[id^="image_set_"] > a').on( 'click', function(event) {
      event.preventDefault();
      var image_url = $(this).attr('href');
      var title = $(this).data('title');
      // console.log(title + " " + image_url + " " + imageSetOverlayClass);
      imageSetOverlay = $('<a href="#" class="'+imageSetOverlayClass+'"><img src="'+image_url+'"><p>'+title+'</p></a>');
      $('#layout').append(imageSetOverlay);
    });

    $('#layout').on( 'mouseup', function(event) {
      event.preventDefault();
      $('.'+imageSetOverlayClass).remove();
    });

    if (!$('body.site_map').length) {
      $('.wrap-content ul:not(.pagination) > li').wrapInner('<span></span>');
      $('.wrap-content ol > li').wrapInner('<span></span>');
    }

    $('.video-wrapper iframe').parent().addClass("with-iframe");

    $('.fragments table').wrap('<div class="table-wrap"></div>');

    var logoHeight = $('.mobile-logo').outerHeight();
    if ($('.home .hero-content').css("justify-content") == "flex-start") {
      $('.home .hero-content .heading').css("margin-top", logoHeight+"px");
    }

    var figureImageWidth = $('figure img').outerWidth();
    if ($(window).width() > 768 && !$('body').hasClass("gallery-layout")) {
      $('figure').css("width", figureImageWidth+"px")
    }

    // Office Tour Custom
    var officeTourIframeUrl = $('.office-tour-poster').data('iframe-src');
    var officeTourIframeMarkUp = '<iframe allowfullscreen="" frameborder="0" src="'+officeTourIframeUrl+'"></iframe>';
    var officeTourPosterImageUrl = $('.office-tour-iframe-wrapper .office-tour-poster').css('background-image');
    if (typeof officeTourPosterImageUrl !== 'undefined') {
      officeTourPosterImageUrl = officeTourPosterImageUrl.replace(/.*\s?url\([\'\"]?/, '').replace(/[\'\"]?\).*/, '');
    }
    var officeTourPosterImageMarkUp = '<div class="office-tour-poster" data-iframe-src="'+officeTourIframeUrl+'" style="background-image: url(\''+officeTourPosterImageUrl+'\');"></div>';
    $('#svg-office-tour-cta').on('click', function(event) {
      event.preventDefault();
      $('.office-tour-poster').replaceWith(officeTourIframeMarkUp);
      $('#svg-office-tour-cta').hide();
    })
    $('.office-tour-iframe-wrapper').on('mouseleave', function(event) {
      event.preventDefault();
      $('.office-tour-iframe-wrapper iframe').prepend(officeTourPosterImageMarkUp);
      $('#svg-office-tour-cta').show();
    })
    $('.office-tour-iframe-wrapper').on('touchmove', function(event) {
      event.preventDefault();
      $('.office-tour-iframe-wrapper iframe').prepend(officeTourPosterImageMarkUp);
      $('#svg-office-tour-cta').show();
    })

    // stops the bg image from changing size on mobile scroll for ios
    if ($(window).width() < 900 && !$('.masthead.hero-full-height.stacked-mobile')) {
      var headerHeight = $('.masthead.hero-full-height').height();
      $('.masthead.hero-full-height').height(headerHeight);
      $('.masthead.hero-full-height .image-background').height(headerHeight);
      $('.masthead.hero-full-height').css('min-height', '0');
      $('.masthead.hero-full-height .image-background').css('min-height', '0');
    } else if ($(window).width() < 900 && $('.masthead.hero-full-height.stacked-mobile')) {
      var docHeight = $( window ).height();
      // var heroHeight = ($('.masthead.hero-full-height.stacked-mobile .hero-content').height());
      // var initialMastheadHeight = ($('.masthead.hero-full-height.stacked-mobile').height());
      // $('.masthead.hero-full-height.stacked-mobile .hero-content').height(heroHeight);
      // $('.masthead.hero-full-height.stacked-mobile .image-background').css('height', 'auto');
      $('.masthead.hero-full-height.stacked-mobile .image-background img').css('height', docHeight);
      // $('.masthead.hero-full-height.stacked-mobile').css('min-height', '0');
      // $('.masthead.hero-full-height.stacked-mobile').height(initialMastheadHeight);
    };

    // Hanging chad fix
    for (var i = 0; i < 40; i++) {
      unHangChad(4, '.affiliations .wrap-content', 'img');
    }

  });

  $(window).resize(function() {
  });

  $(window).scroll(function () {
    navChangeOnScroll('.site-navigation', 300);
  });

})(window.jQuery);

var b = document.documentElement;
b.setAttribute('data-useragent',  navigator.userAgent);
b.setAttribute('data-platform', navigator.platform);
